import React from 'react';
import useStyles from './styles/ContactAvatar.styles';
import OnlineBadge from "./styles/ContactAvatarOnlineBadge.styles";
import OfflineBadge from "./styles/ContactAvatarOfflineBadge.styles";
import Avatar from '@material-ui/core/Avatar';

/**
 * React component for user avatar.
 * @param props Properties: name, online
 * @returns {JSX.Element}
 * @constructor
 */
export default function ContactAvatar(props) {
  const classes = useStyles();

  let badge = (
    <OfflineBadge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot"
    >
      <Avatar>{props.name.charAt(0)}</Avatar>
    </OfflineBadge>
  );

  if (props.online) {
    badge = (
      <OnlineBadge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar>{props.name.charAt(0)}</Avatar>
      </OnlineBadge>
    );
  }

  return (
    <div className={classes.root}>
      {badge}
    </div>
  );
}